<template>
  <div id="app">
    <a-page-header title="地址列表"></a-page-header>
    <a-alert v-if="!live" type="error" message="未连接到CEX服务器" />
    <a-table :columns="columns" :data-source="addressList" :pagination="false">
      <template slot="action" slot-scope="item">
        <a-space>
          <a-button type="primary" @click="subscribe(item)" :loading="item.loading" v-if="!item.subscribe">订阅</a-button>
          <a-button type="danger" @click="unsubscribe(item)" :loading="item.loading" v-else>取消订阅</a-button>
        </a-space>
      </template>
    </a-table>
    <!-- <a-page-header title="报警列表" /> -->
    <!-- <a-table :columns="alertColumns" :data-source="alertList" :pagination="false"></a-table> -->
    <audio id="huobi">
      <source :src="audios.huobi" type="audio/x-wav" />
      <embed height="100" width="100" :src="audios.huobi" />
    </audio>
    <audio id="gate">
      <source :src="audios.gate" type="audio/x-wav" />
      <embed height="100" width="100" :src="audios.gate" />
    </audio>
    <audio id="binance">
      <source :src="audios.binance" type="audio/x-wav" />
      <embed height="100" width="100" :src="audios.binance" />
    </audio>
    <audio id="eth">
      <source :src="audios.eth" type="audio/x-wav" />
      <embed height="100" width="100" :src="audios.eth" />
    </audio>
    <audio id="success">
      <source :src="audios.success" type="audio/x-wav" />
      <embed height="100" width="100" :src="audios.success" />
    </audio>
    <audio id="error">
      <source :src="audios.error" type="audio/x-wav" />
      <embed height="100" width="100" :src="audios.error" />
    </audio>
    <audio id="binance-out">
      <source :src="audios.binanceOut" type="audio/x-wav" />
      <embed height="100" width="100" :src="audios.binanceOut" />
    </audio>
  </div>
</template>

<script>

// const base = 'http://127.0.0.1:7001';
const base = 'https://amapi.listviewss.com';
const audios = {
  huobi: require('./assets/huobi.wav'),
  gate: require('./assets/gate.wav'),
  binance: require('./assets/binance.wav'),
  eth: require('./assets/eth.wav'),
  success: require('./assets/success.wav'),
  error: require('./assets/error.wav'),
  binanceOut: require('./assets/binance-out.wav')
}

export default {
  name: 'App',
  data() {
    return {
      audios,
      live: false,
      liveEth: false,
      columns: [{
        title: '名称',
        dataIndex: 'name',
        key: 'name'
      }, {
        title: '地址',
        dataIndex: 'address',
        key: 'address'
      }, {
        title: '操作',
        scopedSlots: { customRender: 'action' },
      }],
      alertColumns: [{
      title: '地址',
      dataIndex: 'address',
      key: 'address'
      }, {
      title: '操作',
      scopedSlots: { customRender: 'action' },
      }],
      addressList: [],
      tokens: {},
      tableData: [],
      alertList: []
    }
  },
  sockets: {
    connect() {
      this.live = true;
      (this.addressList || []).forEach(node => {
        if (node.subscribe) {
          this.subscribe(node);
        }
      });
    },
    disconnect() {
      this.live = false;
    },
    alert (item) {
      this.play(item.event || item.type);
    },
  },
  methods: {
    async init() {
      const list = await this.getAddressList();
      this.addressList = (list.data.list).map(node => {
        return {
          ...node,
          loading: false,
          subscribe: false
        }
      });
    },
    toFixed(n, f) {
      return parseFloat(n).toFixed(f)
    },
    getAddressList() {
      const url = `${base}/api/address/list`;
      return this.axios.get(url).then((response) => {
        return response.data;
      })
    },
    subscribe(item) {
      item.loading = true;
      const target = item.type === 'eth' ? this.ethSocket : this.$socket;
      target.emit('subscribe', {
        address: item.address
      }, () => {
        item.loading = false;
        item.subscribe = true;
      });
    },
    unsubscribe(item) {
      item.subscribe = true;
      const target = item.type === 'eth' ? this.ethSocket : this.$socket;
      target.emit('unsubscribe', {
        address: item.address
      }, () => {
        item.loading = false;
        item.subscribe = false;
      });
    },
    play(id) {
      const player = document.getElementById(id);
      if (player) {
        player.play();
      }
    }
  },
  mounted () {
    this.init();
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  padding: 20px;
  max-width: 960px;
  margin: 0 auto;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import { Table, Row, Col, Button, PageHeader, Space, Alert } from 'ant-design-vue';
import VueSocketIO from 'vue-socket.io'
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'ant-design-vue/dist/antd.css'; // or 'ant-design-vue/dist/antd.less'

Vue.config.productionTip = false
Vue.use(Table);
Vue.use(Row);
Vue.use(Col);
Vue.use(Button);
Vue.use(PageHeader);
Vue.use(Space);
Vue.use(Alert);

Vue.use(VueAxios, axios)
Vue.prototype.$host = '';
Vue.use(new VueSocketIO({
  debug: false,
  connection: 'wss://amapi.listviewss.com',
}))

new Vue({
  render: h => h(App),
}).$mount('#app')
